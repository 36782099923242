import { mobile } from "components/templates/Breakpoints";
import styled from "styled-components";

export const CenteredButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 7.5rem;

  ${mobile} {
    padding-bottom: 4rem;
  }
`;

export const VideoWrapper = styled.div`
  max-width: 34rem;

  svg {
    border-radius: 0.5rem;
    display: block;
  }
`;
